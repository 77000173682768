import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AuthenticationService } from "../authentication/service/authentication.service";

@Injectable({
  providedIn: "root",
})
export class VendorService {
  constructor(private http: HttpClient, private auth: AuthenticationService) {}

  get headerOptionsRunTime() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    headers = headers.append("Authorization", "Bearer " + this.auth.token);
    return headers;
  }


  GetVendors() {
    return this.http.get(environment.vendorApi + "vendor/vendor", {
        headers: this.headerOptionsRunTime,
      });
  }
  CreateVendor(data: any) {
    return this.http.post(environment.vendorApi + "vendor/create" ,data, {
      headers: this.headerOptionsRunTime,
    });
  }
  CreateVendorByLink(data: any) {
    return this.http.post(environment.vendorApi + "vendor/register-vendor-link" ,data, {
      headers: this.headerOptionsRunTime,
    });
  }

  UpdateVendor(data: any, id: string) {
    return this.http.put(environment.vendorApi+"vendor/"  + id, data, {
      headers: this.headerOptionsRunTime,
    });
  }
}